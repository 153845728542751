import React, { useState } from 'react'
import {
  Alert, Button, Form, Row, Col,
} from 'react-bootstrap'

const FNCESweepstakesForm = () => {
  const [validated, setValidated] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [formDisabled, setFormDisabled] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const processForm = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget

    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
      setValidated(true)
      return
    }
    setValidated(true)
    setFormDisabled(true)

    // Send to Klaviyo
    fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      headers: {
        accept: 'application/json, text/plain, */*',
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        g: 'WCDqNp',
        email: emailValue,
      }),
      method: 'POST',
    })
      .then(() => {
        setShowAlert(true)
        // isBrowser && (window.location.href = '/hub');
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={processForm}
      data-ad-element-type="Referral Join Form"
      data-ad-text="Win Meati™. Get Nutrition Guide."
      data-ad-button-location="body"
      id="FNCESweepstakesForm"
      // className="bg-primary p-2 rounded-6"
    >
      <Row className="g-1">
        <Col xs="8">
          <Form.Group>
            <Form.Control
              type="email"
              placeholder="Email"
              className="p-1"
              required
              value={emailValue}
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
              onChange={(e) => setEmailValue(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email address
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs="4">
          <Button
            variant="primary"
            className="btn-lg w-100 h-100"
            type="submit"
            disabled={formDisabled}
            data-ad-element-type="FNCE sweepstakes form submit"
            data-ad-text="FNCE Sweepstakes subscribe"
            data-ad-button-location="body"
            data-ad-button-id="version 1"
          >
            Enter
          </Button>
        </Col>
      </Row>

      <Alert variant="success" className="mt-1 mb-0" show={showAlert}>
        <h2 className="fs-6">We're glad you're here!</h2>
        <p className="mb-0 text-dark lh-1">Check your email for a link to your nutrition guide!</p>
      </Alert>

      <small className="mt-12"><a href="/legal/rules/fnce-sweepstakes" target="_blank" rel="noopener noreferrer" className="text-muted">See terms and conditions</a></small>

    </Form>

  )
}

export default FNCESweepstakesForm
