import * as React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Container } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../../components/seo'
import Layout from '../../components/layout'
import FNCESweepstakesForm from '../../components/common/fnce-sweepstakes-form'
import useSiteMetadata from '../../components/utilities/get-site-metadata'
import featuredImage from '../../images/hero/fnce-hero.jpg'

const ReferralJoinPage = () => {
  const { siteUrl } = useSiteMetadata()
  const imagePath = siteUrl + featuredImage

  const meta = [
    {
      name: 'og:image',
      content: imagePath,
    },
    {
      name: 'twitter:image',
      content: imagePath,
    },
    {
      name: 'og: title',
      content: 'Enter to Win',
    },
    {
      name: 'og:description',
      content: 'Enter for a chance to win a Meati™ subscription for you and 5 of your top clients for 1 year, plus Meati swag.',
    },
  ]

  return (
    <Layout>
      <Seo title="Refer a Friend'" />
      <Seo meta={meta} title="Enter to Win" />
      <header id="fnceHero" className="bg-warning py-1 py-lg-3 py-xxl-5">
        <Container>
          <Row>
            <Col lg="6">
              <StaticImage
                src="../../images/hero/fnce-hero.jpg"
                quality={95}
                formats={['AUTO', 'WEBP']}
                alt="Meati™ Referrals"
                placeholder="blurred"
                layout="fullWidth"
                className="rounded-5"
                imgClassName="rounded-5"
                aspectRatio={1}
              />
            </Col>
            <Col lg="6" className="align-self-center">
              <Container className="py-2 py-lg-0">
                <h1 className="font-swish text-primary fw-bold fst-italic">
                  <span className="d-block">It's a </span>
                  Giveaway!
                </h1>
                <p>
                  Enter for a chance to win a Meati™ subscription for you and 5 of your top clients for 1 year, plus Meati™ swag. To say "thanks", we'll also send a copy of our very first Meati™ Nutrition Guide to your inbox!
                </p>
                <FNCESweepstakesForm />
              </Container>
            </Col>
          </Row>
        </Container>
      </header>
    </Layout>
  )
}

export default ReferralJoinPage
